import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductListComponent } from './components/product-list/product-list.component';
import { RouterModule } from '@angular/router';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DateFormatterPipe } from '../core/pipes/dateformatter.pipe';
import { SearchFilterComponent } from './components/search-filter/search-filter.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSortModule } from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomerDetailsComponent } from '../shared/components/customer-details/customer-details.component';
import { MatDividerModule } from '@angular/material/divider';
import { TotalComponent } from './components/total/total.component';
import { HintComponent } from './components/hint/hint.component';
import { PartsDocDialogComponent } from './components/parts-doc-dialog/parts-doc-dialog.component';
import { CopyButtonComponent } from './components/copy-button/copy-button.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatNativeDateModule, MatOptionModule, MatRippleModule } from '@angular/material/core';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ItemDelayedDialogComponent } from '../orders/components/item-delayed-dialog/item-delayed-dialog.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { TwoDigitDecimalNumberDirective } from './directives/two-digit-decimal-number.directive';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { BackButtonDirective } from './directives/back-button.directive';
import { UtilService } from './services/util.service';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MultiCopyComponent } from './components/multi-copy/multi-copy.component';
import { IonicModule } from '@ionic/angular';
import { ToggleableInputComponent } from './components/toggleable-input/toggleable-input.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { MatIconModule } from '@angular/material/icon';
import { ComponentLibModule } from '@autoflow/angular-ux-components';
import { QuotesService } from '../quotes/services/quotes.service';
import { PartsService } from '../parts/services/parts.service';
import { CsvFileService } from './services/csv-file.service';

const IMPORTS = [
  CommonModule,
  IonicModule,
  MatOptionModule,
  MatTableModule,
  RouterModule,
  MatButtonModule,
  MatTooltipModule,
  MatFormFieldModule,
  MatSortModule,
  MatInputModule,
  MatSelectModule,
  MatDividerModule,
  FormsModule,
  ReactiveFormsModule,
  ClipboardModule,
  MatRippleModule,
  MatCheckboxModule,
  MatRadioModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatIconModule,
  MatMenuModule,
  ComponentLibModule
];
const DECLARATIONS = [
  ProductListComponent,
  DateFormatterPipe,
  SearchFilterComponent,
  CustomerDetailsComponent,
  TotalComponent,
  HintComponent,
  PartsDocDialogComponent,
  CopyButtonComponent,
  LoadingSpinnerComponent,
  ItemDelayedDialogComponent,
  TwoDigitDecimalNumberDirective,
  BackButtonDirective,
  MultiCopyComponent,
  ToggleableInputComponent,
  PaginatorComponent
];
@NgModule({
  declarations: [...DECLARATIONS],
  imports: [...IMPORTS],
  exports: [...IMPORTS, ...DECLARATIONS],
  providers: [
    QuotesService,
    PartsService,
    UtilService,
    CsvFileService,
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 3000 } }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
