import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { UtilService } from '../../shared/services/util.service';
import { predicates } from '../../shared/predicates';
import { Part, PartsCalculation } from '../models/part.model';
import { UserService } from '../../user.service';
import { ERoundingScheme } from '../models/parts-list.model';
import { RoundingService } from './rounding.service';
import { CalculationsService } from './calculations.service';
import { ErrorsService } from './errors.service';
import { QuotesService } from '../../quotes/services/quotes.service';
import { CAppPart, CPart, CTLAPart, EScheme, TAllPartFields } from '../models/parts-list.model';
import dayjs from 'dayjs';
import Big from 'big.js';
import { PartsCopyComponent } from '../components/parts/parts-copy/parts-copy.component';

@Injectable()
export class PartsService {
  dialogsClose: Subject<null> = new Subject();
  discountToTwoDecimals = false;
  partAddSectionShowHide: Subject<null> = new Subject();
  partAddSectionSubmit: Subject<null> = new Subject();
  partDescriptionUpdate: Subject<Part> = new Subject();
  partDescriptionCancel: Subject<Part> = new Subject();

  partRowAdvance: Subject<Part> = new Subject();
  shiftKeyPress: Subject<boolean> = new Subject();

  /* eslint-disable-next-line @typescript-eslint/member-ordering */
  private _partsList$ = new BehaviorSubject<(CPart | CTLAPart | CAppPart)[]>([]);
  /* eslint-disable-next-line @typescript-eslint/member-ordering */
  partsList$ = this._partsList$.asObservable(); // returning as observable ensures value is not unintentionally null

  constructor(
    private calculationsService: CalculationsService,
    private errorsService: ErrorsService,
    private quotesService: QuotesService,
    private roundingService: RoundingService,
    private utilService: UtilService,
    private userService: UserService
  ) {
    this.roundingService.roundingScheme = this.userService.isSubscribed()
      ? ERoundingScheme.ROUND_UP
      : ERoundingScheme.AUTO;
  }

  calculateDiscount(part: Part): string {
    // if rrp or net is 0 avoid dividing by 0
    return Number(part.net) === 0 || Number(part.rrp) === 0
      ? '0'
      : this.roundingService.roundValue(100 - +part.net / +part.quantity / (+part.rrp / 100));
  }

  calculateDiscountAmount(part: Part): number {
    return Big(part.quantity).mul(part.rrp).minus(part.net).toNumber();
  }

  calculateNet(parts: Part[], onlyNet = false): number {
    return parts.map((part) => +part.net || (onlyNet ? 0 : +part.rrp)).reduce((prev, curr) => +prev + +curr, 0);
  }

  calculateNetValue(part: Part): string {
    if (part.unit && part.quantity) {
      return this.roundingService.roundValue(Big(part.unit).mul(part.quantity).toNumber());
    } else {
      return '0';
    }
  }

  /**
   * Calculates the total value for the supplied parts
   */
  calculateTotals(parts: Part[], includeCancelled: boolean = false): PartsCalculation {
    let totItems = 0;
    let subtotal = 0;
    let surcharge = 0;
    let vatCalculated = 0;
    const vatPercent = '20%';
    const vatRate = 0.2;

    parts
      .filter(
        (part) =>
          predicates.notRemovedFromParts(part) &&
          predicates.notPartBreakdownHeader(part) &&
          (includeCancelled || predicates.notCancelledFromParts(part))
      )
      .forEach((part) => {
        const quantity = Big(part.quantity).toNumber();
        const rrp = Big(part.rrp).toNumber();
        const unit = Big(part.unit).toNumber();
        totItems += quantity;
        surcharge += Big(part.surcharge).mul(part.quantity).toNumber();
        if (!part.net) {
          part.net = this.roundingService.roundValue(
            Big(quantity).mul(unit).toNumber(),
            this.userService.isSubscribed() ? ERoundingScheme.ROUND_UP : ERoundingScheme.AUTO
          );
          subtotal += Big(part.net).toNumber();
        } else {
          const discountAmount = part.discountAmount ?? Big(part.rrp).minus(part.unit).toNumber();
          part.discountAmount = Big(discountAmount).toNumber() || 0;
          subtotal += unit
            ? Big(part.net).toNumber()
            : +this.roundingService.roundValue(Big(quantity).mul(unit).minus(part.discountAmount));
        }

        if (rrp) {
          if (+part.discount === 0 || !(+part.discount && +part.net !== 0)) {
            let discount = Number(part.unit) === 0 ? 1 : Big(part.unit).div(part.rrp).toNumber();
            discount = Big(1).minus(discount);
            discount = Big(discount).mul(100).toString();
            part.discount = this.roundingService.roundValue(
              discount,
              this.userService.isSubscribed() ? ERoundingScheme.ROUND_UP : ERoundingScheme.AUTO
            );
          }
        }

        const subtotalVat = subtotal * vatRate;

        vatCalculated = subtotalVat;
      });

    return {
      totalItems: totItems,
      subtotal: +this.roundingService.roundValue(subtotal),
      surcharge,
      total: +this.roundingService.roundValue(+(vatCalculated + subtotal)),
      vatCalculated: +this.roundingService.roundValue(+vatCalculated),
      vatPercent,
      vatRate
    };
  }

  calculateUnitValue(part: Part): string {
    return this.roundingService.roundValue(Big(part.net).div(part.quantity));
  }

  decrementQuantity(quantity: number): number {
    if (!quantity) {
      quantity = 1;
    } else {
      --quantity;
    }

    return quantity;
  }

  handleMouseAndKeyboardEvents(): void {
    // Shift key
    this.utilService.getObservableKeyboardEvent('keydown', 'Shift').subscribe(() => this.shiftKeyPress.next(true));
    this.utilService.getObservableKeyboardEvent('keyup', 'Shift').subscribe(() => this.shiftKeyPress.next(false));

    // Escape key
    this.utilService.getObservableKeyboardEvent('keyup', 'Escape').subscribe(() => {
      this.dialogsClose.next(null);
    });

    // Enter key
    this.utilService.getObservableKeyboardEvent('keyup', 'Enter').subscribe((e: KeyboardEvent) => {
      const target: any = e.target;
      let part = null;

      const partAddScection = this.utilService.getClosestParent(target, 'parts-add__');
      const partDescriptionDialog = this.utilService.getClosestParent(target);
      const partQuantity = this.utilService.getClosestParent(target, 'qty');

      if (partDescriptionDialog) {
        part = this.getPartFromEvent({ target: target.parentElement.firstElementChild });
        if (target instanceof HTMLInputElement || target.innerText.toLowerCase().includes('save')) {
          // Save new value
          this.partDescriptionUpdate.next(part);
        } else {
          // Cancel
          this.partDescriptionCancel.next(part);
        }
      } else if (partAddScection) {
        this.partAddSectionSubmit.next(null);
      } else if (this.isNonDialogField(target as HTMLElement) || partQuantity) {
        // Advance to the next or previous row, same column
        part = this.getPartFromEvent(e);
        this.partRowAdvance.next(part);
        e.stopPropagation();
      }
    });

    // Backspace key
    this.utilService.getObservableKeyboardEvent('keyup', 'Backspace').subscribe((e: KeyboardEvent) => {
      e.stopPropagation();
    });

    // Click event
    this.utilService.getObservableMouseEvent('click').subscribe((e: MouseEvent) => {
      const target: any = e.target;
      const partAddScection = this.utilService.getClosestParent(target, 'add-part');

      if (this.isDialogOpen() && !this.isPartOfDialog(e.target as any)) {
        this.dialogsClose.next(null);
      } else if (partAddScection) {
        this.partAddSectionShowHide.next(null);
      } else {
        e.stopPropagation();
      }
    });
  }

  htmlSpecialChars(input: string): string {
    const map = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      // prettier-ignore
      '\'': '&#039;'
    };

    return input.replace(/[&<>"']/g, (m) => map[m]);
  }

  incrementQuantity(quantity: number): number {
    if (!quantity) {
      quantity = 1;
    }

    ++quantity;
    return quantity;
  }

  validatePartNumericFields(part: Part): boolean {
    return (
      +part.net > 0 &&
      +part.rrp > 0 &&
      +part.unit > 0 &&
      +part.discount >= 0 &&
      +part.discount < 100 &&
      +part.rrp >= +part.unit
    );
  }

  /*
    FUNCS MIGRATED FROM COMPONENT LIB
  */

  /**
   * checks whether part has received a data or lookup error response
   * @param part
   * @returns
   */
  getLookupErrorFlags(part: CPart): { data: boolean; lookup: boolean } {
    const error = { data: false, lookup: false };
    const lookupError = part.lookupWarnings?.includes('LOOKUP_ERROR');
    if (lookupError) {
      error.lookup = true;
    } else if (part.lookupWarnings?.length) {
      error.data = true;
    }
    return error;
  }

  /**
   * checks whether part has afterMarket property and gets value if not undefined
   * @param part
   * @returns boolean
   */
  selectParts(part?: CPart | CTLAPart | CAppPart, scheme?: string): boolean {
    switch (scheme) {
      case EScheme.APP:
        return !part?.grouped;

      case EScheme.GEN:
      case EScheme.TLA:
        // That the part exists
        return !!part?.createdAt || part?.lookupMatched || (part?.lookupWarnings ?? []).length > 0;

      default:
        return false;
    }
  }

  updateParts(parts: (CPart | CTLAPart | CAppPart)[]): void {
    this._partsList$.next(parts);
  }

  /**
   * Updates the value of a field, updating any other fields or modifying value as necessary
   * @param value
   * @param field
   */
  updateValue(
    value: string | number,
    field: TAllPartFields,
    part: CPart | CAppPart | CTLAPart,
    scheme: EScheme
  ): CPart | CTLAPart | CAppPart | undefined {
    let upd: Record<string, string | number> = {};
    let appPart: CAppPart;
    upd[field] = value;
    switch (field) {
      case 'rrp':
        let { discount } = part;
        discount = discount ? Big(discount).div(100).toNumber() : 0;
        discount = Big(1).minus(discount).toNumber();
        const newUnit = Big(value).mul(discount).toNumber();
        const newNet = this.roundingService.roundValue(
          Big(newUnit).mul(part.quantity),
          this.userService.isSubscribed() ? ERoundingScheme.ROUND_UP : ERoundingScheme.AUTO
        );
        upd.unit = newUnit;
        upd.net = newNet;
        if (scheme === 'APP' && (part as CAppPart).afterMarket) {
          appPart = {
            ...part,
            rrp: value,
            discount: Big(upd.discount || part.discount).toString()
          } as CAppPart;
          upd = {
            ...upd,
            ...this.calculateAppValues(appPart, false, true)
          };
        }
        break;
      case 'surcharge':
        break;
      case 'quantity':
        upd.net = this.calculationsService.calculateNetValue(+part.rrp, +value as number, +(part.discount || 0));
        break;
      case 'unit':
        let calculatedDiscount = Number(value) === 0 ? 1 : Big(value).div(part.rrp).toNumber();
        calculatedDiscount = Big(1).minus(calculatedDiscount);
        calculatedDiscount = Big(calculatedDiscount).mul(100).toString();
        upd.discount = calculatedDiscount;
        upd.net = this.calculationsService.calculateNetValue(+part.rrp, part.quantity, calculatedDiscount);
        if (scheme === 'APP' && +((part as CAppPart).appDiscount + '') > 0) {
          let combinedDiscount = Big(calculatedDiscount).div(100);
          combinedDiscount = Big((part as CAppPart).appDiscount || 0).add(combinedDiscount);
          combinedDiscount = Big(1).minus(combinedDiscount).toNumber();
          let appPrice = Big(part.rrp).mul(combinedDiscount).toNumber();
          appPrice = this.roundingService.roundValue(Big(appPrice).mul(part.quantity), ERoundingScheme.ROUND_UP);
          upd.appPrice = appPrice;
        }
        break;
      case 'net':
        // update unit
        upd.unit = Big(value).div(part.quantity).toNumber();
        // update discount
        let disc = Number(part.unit) === 0 ? 1 : Big(upd.unit).div(part.rrp).toNumber();
        disc = Big(1).minus(disc);
        disc = Big(disc).mul(100).toString();
        upd.discount = disc;
        if (scheme === 'APP' && +((part as CAppPart).appDiscount + '') > 0) {
          let combinedDiscount = Big(disc).div(100);
          combinedDiscount = Big((part as CAppPart).appDiscount || 0).add(combinedDiscount);
          combinedDiscount = Big(1).minus(combinedDiscount).toNumber();
          let appPrice = Big(part.rrp).mul(combinedDiscount).toNumber();
          appPrice = this.roundingService.roundValue(Big(appPrice).mul(part.quantity), ERoundingScheme.ROUND_UP);
          upd.appPrice = appPrice;
        }
        break;
      case 'discount':
        value = value === '' ? '0' : value;
        upd[field] = value;
        const discountDivided = Big(value).div(100);
        const discountDeducted = Big(1).minus(discountDivided);
        upd.unit = Big(part.rrp || '0').mul(discountDeducted).toNumber();
        const rrp = Big(part.rrp || '0').toNumber();
        const quantity = part.quantity || 1;
        upd.net = this.calculationsService.calculateNetValue(+(rrp || '0'), quantity, +value);
        if (scheme === 'APP' && (part as CAppPart).afterMarket) {
          appPart = {
            ...part,
            discount: Big(value).toString(),
            net: Big(upd.net).toString(),
            unit: Big(upd.unit).toString()
          } as CAppPart;
          upd = {
            ...upd,
            ...this.calculateAppValues(appPart, false, true)
          };
        }
        break;
      case 'appDiscount':
        appPart = {
          ...part,
          appDiscount: value
        } as CAppPart;
        upd = {
          ...upd,
          ...this.calculateAppValues(appPart, false, true)
        };
        break;
      case 'appPrice':
        appPart = {
          ...part,
          appPrice: value
        } as CAppPart;
        upd = {
          ...upd,
          ...this.calculateAppValues(appPart, true, false)
        };
        break;
      default:
        break;
    }
    // validation
    Object.entries(upd).forEach((obj) => {
      const key = obj[0];
      const val = obj[1];
      if (key === 'net' && Object.keys(upd).includes('quantity')) {
        // no need to validate net when quantity changed
        return;
      }
      this.validateValue(val, key as TAllPartFields, part);
    });
    part.updated = true;
    part.updatedAt = dayjs();
    this.quotesService.updatePart({ uniquePartId: part.uniquePartId ?? '', update: upd }); // update row
    return {
      ...part,
      ...upd
    };
  }

  /**
   * Validate value, updating errors if applicable
   * @param value
   * @param field
   */
  validateValue(value: string | number, field: TAllPartFields, part: CPart | CAppPart | CTLAPart): void {
    if (!(part.createdAt || (part as any).touched)) {
      // Ignore validation attempts on part being added but not yet touched
      return;
    }
    const isEmptyField = typeof value === 'string' && (value as string).length === 0;
    if (isEmptyField) {
      const errorMsg = 'Please enter a value';
      this.errorsService.appendError(
        this.errorsService.createError(part.uniquePartId ?? '', field, errorMsg, part.removed)
      );
    } else {
      const errorMsg = 'Please enter a value';
      this.errorsService.removeError(
        this.errorsService.createError(part.uniquePartId ?? '', field, errorMsg, part.removed)
      );
    }

    if (isEmptyField || value !== '') {
      // field specific
      if (field === 'partNumber') {
        const invalidPartNumMsg = 'This part number is not allowed.';
        if ((value as string).includes('SEE')) {
          if ((part.partCode ?? '') !== '') {
            this.errorsService.appendError(
              this.errorsService.createError(part.uniquePartId ?? '', field, invalidPartNumMsg, part.removed)
            );
          }
        } else {
          if ((part.partCode ?? '') !== '') {
            this.errorsService.removeError(
              this.errorsService.createError(part.uniquePartId ?? '', field, invalidPartNumMsg, part.removed)
            );
          }
        }
      }
      if (!part.kitHeader) {
        if (field === 'net' || field === 'unit' || field === 'rrp') {
          const lessThan0Msg = 'Unit Retail / Net Value / Unit Price cannot be less than £0.01';
          if (+value < 0.01) {
            this.errorsService.appendError(
              this.errorsService.createError(part.uniquePartId ?? '', field, lessThan0Msg, part.removed)
            );
          } else {
            this.errorsService.removeError(
              this.errorsService.createError(part.uniquePartId ?? '', field, lessThan0Msg, part.removed)
            );
          }
          const rrpLessThanNetMsg = 'RRP can not be less than unit price';
          if (part !== undefined && part.unit !== undefined) {
            const rrp = field === 'rrp' ? +value : +part.rrp;
            let unit;
            if (field === 'unit') {
              unit = +value;
            } else if (field === 'net') {
              unit = +this.roundingService.roundValue(+value / +part.quantity);
            } else {
              unit = +part.unit;
            }
            if (rrp < unit) {
              this.errorsService.appendError(
                this.errorsService.createError(part.uniquePartId ?? '', 'rrp', rrpLessThanNetMsg, part.removed)
              );
              this.errorsService.appendError(
                this.errorsService.createError(part.uniquePartId ?? '', 'unit', rrpLessThanNetMsg, part.removed)
              );
              this.errorsService.appendError(
                this.errorsService.createError(part.uniquePartId ?? '', 'net', rrpLessThanNetMsg, part.removed)
              );
            } else {
              this.errorsService.removeError(
                this.errorsService.createError(part.uniquePartId ?? '', 'rrp', rrpLessThanNetMsg, part.removed)
              );
              this.errorsService.removeError(
                this.errorsService.createError(part.uniquePartId ?? '', 'unit', rrpLessThanNetMsg, part.removed)
              );
              this.errorsService.removeError(
                this.errorsService.createError(part.uniquePartId ?? '', 'net', rrpLessThanNetMsg, part.removed)
              );
            }
          }
        }
      }

      if (!part.kitHeader && field === 'discount') {
        const partAsTla = part as CPart;
        const discountMax = +(partAsTla.discountMaximum ?? '0.45') * 100;
        const comparisonVal = +value > discountMax;
        if (comparisonVal) {
          this.errorsService.appendError(
            this.errorsService.createError(
              part.uniquePartId ?? '',
              field,
              `Discount can not be greater than ${discountMax}%`,
              part.removed
            )
          );
        } else {
          this.errorsService.removeError(
            this.errorsService.createError(
              part.uniquePartId ?? '',
              field,
              `Discount can not be greater than ${discountMax}%`,
              part.removed
            )
          );
        }
        // TODO: Worth checking against NaN here?
        if (+value < 0) {
          const errorMsg = 'Discount can not be less than 0%';
          this.errorsService.appendError(
            this.errorsService.createError(part.uniquePartId ?? '', field, errorMsg, part.removed)
          );
        } else {
          const errorMsg = 'Discount can not be less than 0%';
          this.errorsService.removeError(
            this.errorsService.createError(part.uniquePartId ?? '', field, errorMsg, part.removed)
          );
        }
      }
    }
  }

  /*
    =================================
  */

  prepAppPartsForSaving(parts: CAppPart[], quotedParts: any): CAppPart[] {
    parts.forEach((p) => {
      p.appDiscount = Big(p.appDiscount || 0)
        .div(100)
        .toString();
      const quotedPart = quotedParts.find((qp) => qp.uniquePartId === p.uniquePartId);
      if (quotedPart) {
        if (quotedPart.hasOwnProperty('appDiscount')) {
          if (quotedPart.appDiscount !== p.appDiscount) {
            p.updated = true;
            p.updatedAt = dayjs();
          }
        }
      }
    });
    return parts as CAppPart[];
  }

  private calculateAppValues(
    part: CAppPart,
    calculatingDiscount = true,
    calculatingPrice = true
  ): Record<string, string | number> {
    const updatedValues = {
      appPrice: null,
      appDiscount: null
    };
    const partAppDiscount = part.appDiscount || 0;
    const partAppPrice = part.appPrice || part.net;
    const maxDiscount = this.getMaxAppDiscount(part);
    const maxAppDiscount = this.getMaxAppDiscount(part);
    if (calculatingPrice) {
      updatedValues.appDiscount = Math.max(0, Math.min(maxAppDiscount, Big(partAppDiscount))).toString();
      const discount = updatedValues.appDiscount
        ? Big(1).minus(Big(updatedValues.appDiscount).add(part.discount).div(100)).toNumber()
        : 0;
      const appPricePerPart = discount === 0 ? Big(part.rrp).toNumber() : Big(part.rrp).mul(discount).toNumber();
      updatedValues.appPrice = this.roundingService.roundValue(
        Big(appPricePerPart).mul(part.quantity).toNumber(),
        ERoundingScheme.ROUND_UP
      );
    }
    if (calculatingDiscount) {
      const combinedDiscount = Big(part.discount).add(maxDiscount);
      const discountFromOneHundred = Big(100).minus(combinedDiscount);
      const multiplier = Big(discountFromOneHundred).div(100).toNumber();
      const minPrice = Big(
        this.roundingService.roundValue(
          Big(part.rrp).mul(multiplier).mul(part.quantity).toNumber(),
          ERoundingScheme.ROUND_UP
        )
      ).toNumber();
      updatedValues.appPrice = Math.min(Big(part.net), Math.max(minPrice, Big(partAppPrice))).toString();

      let appDiscount = Big(updatedValues.appPrice).div(Big(part.rrp).mul(part.quantity));
      appDiscount = Big(1).minus(appDiscount);
      appDiscount = appDiscount.minus(Big(part.discount).div(100));
      appDiscount = Big(this.roundingService.roundValue(appDiscount), ERoundingScheme.ROUND_UP);
      appDiscount = appDiscount.mul(100).toNumber();

      updatedValues.appDiscount = Big(Math.min(maxAppDiscount, appDiscount)).toString();
    }
    return updatedValues;
  }

  private getMaxDiscount(part: CAppPart): number {
    return Big(part.discountMaximum).mul(100).toNumber();
  }

  private getMaxAppDiscount(part: CAppPart): number {
    return Big(this.getMaxDiscount(part)).minus(part.discount).toNumber();
  }

  private getPartFromEvent(event: any): Part {
    let field = null;
    let target = null;
    const partIDStartIndex = 4;
    if (event.target.id) {
      field = event.target.id.slice(0, event.target.id.indexOf('-'));
    } else {
      field = 'qty';
      target = this.utilService.getClosestParent(event.target, field);
    }
    return {
      [field]: event.target.value,
      uniquePartId: target
        ? target.nextElementSibling.firstElementChild.id.slice(partIDStartIndex)
        : event.target.id.slice(field.length + 1)
    } as any;
  }

  private isDialogOpen(): boolean {
    if (Array.from(document.getElementsByClassName('dialog')).find((node) => !node.className.includes('hidden'))) {
      return true;
    }
    return false;
  }

  private isNonDialogField(target: HTMLElement): boolean {
    const fieldNames = ['partNumber', 'rrp', 'surcharge', 'net', 'unit'];
    return !!fieldNames.find((name) => target.id.includes(name));
  }

  private isPartOfDialog(target: HTMLElement): HTMLElement {
    return this.utilService.getClosestParent(target);
  }
}
